import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Blogs from "../components/Blogs";

const BlogScreen=()=>{
    return (
            <>
                <Navbar/>
                <Blogs/>
                <Footer/>
            </>
    ); 
}
export default BlogScreen;