import React from "react";
import { Box, Typography, Grid } from '@mui/material';
import imghome from '../assests/wall.jpg';
import '../App.css';
import ServiceCard from "../components/ServiceCard";
import ServiceData from "../Constants/ServiceData";

const Products = () => {
  return (
    <Box>
      <Box id="main" sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${imghome})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height:'50vh'
      }}>
        <Grid container sx={{justifyContent:"center", pt:5, my:30}}>
          <Grid item xs={12} sm={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} flexGrow={1}>
            <Box sx={{ p: { xs:8, sm: 8 } }}>
              <Typography variant="h2" color={'white'}>TERIC DATA LABS  PRODUCTS</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{display:'flex', justifyContent:'center', flexGrow:1, padding:8,flexDirection:'column'}}>
      
        <Box sx={{display:'flex', flexDirection:"row" , padding:8, mt:12}}>
          <div style={{height:'2px',backgroundColor:'black', width:'40%'}}></div>
           <Typography variant="h4" justifyContent={'center'}>Products</Typography>  
          <div style={{height:'2px',backgroundColor:'black', width:'40%'}}></div>
        </Box>
        <Box sx={{display:"flex", justifyContent:"center", flexWrap:"wrap", justifyItems:'center'}}>
          {ServiceData.map((serv) => (
            <ServiceCard
              img={serv.img}
              service={serv.title}
              description={serv.description}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Products;
