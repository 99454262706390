import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, Button, Box } from '@mui/material';

const EmailFormCard = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform email sending logic here
    // You can access the email, subject, and body values in the state variables (email, subject, body)
    console.log('Email:', email);
    console.log('Subject:', subject);
    console.log('Body:', body);

    // Clear the form fields
    setEmail('');
    setSubject('');
    setBody('');
  };

  return (
    <Box width="80%" maxWidth={400} marginX="auto">
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            CONTACT US
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Subject"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Body"
              variant="outlined"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button variant="contained" type="submit" color="primary" fullWidth>
              Send
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EmailFormCard;
