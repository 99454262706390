import React from "react";
import { Box} from '@mui/material';
import '../App.css'
import ServiceData from "../Constants/ServiceData";
import ServiceCard from "./ServiceCard";


const Blogs = () => {

  return (
    <Box>
      <Box sx={{display:'flex', justifyContent:'center', flexGrow:1, padding:8,flexDirection:'column'}}>
        
        <Box sx={{display:"flex", justifyContent:"center", flexWrap:"wrap", justifyItems:'center'}}>
        {ServiceData.map((serv)=>(
                    <ServiceCard
                    img={serv.img}
                    service={serv.title}
                    description={serv.description}
                    />

                ))}
        </Box>
      </Box>
     
    </Box>
  );
}

export default Blogs;
