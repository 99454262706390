import React ,{ useEffect , useRef} from "react";
import { Box, Typography, Button, Grid } from '@mui/material';
import imghome from '../assests/wall.jpg';
import wrk from '../assests/wall23.jpg'
import team from '../assests/team.jpg'
import { ArrowDownward } from '@mui/icons-material';
import '../App.css'
import ProCard from "./ProCard";
import ServiceData from "../Constants/ServiceData";
import ServiceCard from "./ServiceCard";


const Welcome = () => {
  const aboutUsRef = useRef(null);

  const scrollToAboutUs = () => {
    aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    const sideImage = document.getElementById('sideimage');
    sideImage.style.animation = 'slide-in 1s ease-out';

    return () => {
      sideImage.style.animation = 'none';
    };
  }, []);

  return (
    <Box>
      <Box id="main" sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${imghome})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height:'100vh'
      }}>
        <Grid container sx={{justifyContent:"center", pt:5, my:30}}>
          <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} flexGrow={1}>
            <Box sx={{ p: { xs:8, sm: 8 } }}>
              <Typography variant="h2" color={'white'} fontFamily={'serif'} padding={4}>AI Data-Driven Brilliance<br/><br /> AI Powered Innovations
                </Typography>
              
              <Box sx={{ display: 'flex', flexDirection: 'row', mt:4 , justifyContent:'center'}}>
                <Button variant="contained" id="btnsaved" onClick={scrollToAboutUs} startIcon={<ArrowDownward style={{fontSize:'32px', color:'black'}}/>} sx={{ borderRadius: 12, backgroundColor:'white', fontSize:'20px', color:'black',justifyItems:'center', justifyContent:'center', animation:'backwards'}}>ABOUT US</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} flexGrow={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={wrk} alt="optical character recognition" style={{maxWidth: '100%',objectFit: 'cover' , animation: 'slide-in 1s ease-out',}} id="sideimage" />
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{display:'flex', justifyContent:'center', flexGrow:1, padding:8,flexDirection:'column'}} id="aboutus" ref={aboutUsRef}>
        <Box sx={{display:'flex', flexDirection:"row" , padding:8, mt:12}}>
          <div style={{height:'2px',backgroundColor:'black', width:'40%'}}></div>
           <Typography variant="h4" justifyContent={'center'}>What We Do</Typography>  
          <div style={{height:'2px',backgroundColor:'black', width:'40%'}}></div>
        </Box>
        
        <Box>
          <ProCard title={"About Us"} 
          description={"We are a data analytics company that unlocks the power of your data to drive business success. Our expert team of data scientists and analysts dives deep into complex datasets, uncovering valuable insights that fuel informed decision-making. By leveraging advanced algorithms and cutting-edge techniques, we transform raw information into actionable intelligence. Stay ahead of the competition with our data analytics solutions, optimizing operations, predicting market trends, and understanding customer preferences."} 
          description2={"Join forces with us and embrace the data-driven revolution. We actively seek partnerships with forward-thinking businesses who understand the value of analytics. Our dedicated business development team collaborates closely with clients, tailoring our solutions to meet their specific needs. With transparent pricing, open communication, and a focus on measurable results, we build long-term relationships based on trust and mutual success."}
          description3={"Located at the heart of innovation in Makerere University, Kampala, Uganda, we thrive in an environment that fosters creativity and collaboration. Makerere University, renowned for its academic excellence, provides us with access to top talent and cutting-edge research. Situated in a bustling business hub, we connect with local and international clients, serving diverse industries such as finance, healthcare, and agriculture."}
          imageUrl={team}
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:"row" , padding:8, mt:12}}>
          <div style={{height:'2px',backgroundColor:'black', width:'40%'}}></div>
           <Typography variant="h4" justifyContent={'center'}>SERVICES</Typography>  
          <div style={{height:'2px',backgroundColor:'black', width:'40%'}}></div>
        </Box>
        <Box sx={{display:"flex", justifyContent:"center", flexWrap:"wrap", justifyItems:'center'}}>
        {ServiceData.map((serv)=>(
                    <ServiceCard
                    img={serv.img}
                    service={serv.title}
                    description={serv.description}
                    />

                ))}
        </Box>
      </Box>
     
    </Box>
  );
}

export default Welcome;
