import React, { useState } from 'react';
import navicon from '../assests/logo.png';
import ug from '../assests/uganda.png'
import { AppBar, Box, Toolbar, Button, Typography, IconButton, Menu, MenuItem, useMediaQuery, ThemeProvider, createTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const theme = createTheme();

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem component={Link} to="/products" onClick={handleMenuClose}>
       Home
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>AboutUs</MenuItem>
      <MenuItem onClick={handleMenuClose}>Products</MenuItem>
      <MenuItem onClick={handleMenuClose}>Programs</MenuItem>
      <MenuItem onClick={handleMenuClose}>Blog</MenuItem>
    </Menu>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 2, mx: 'auto', px: 5 }}>
        <AppBar sx={{ backgroundColor: 'white', color: 'black' }}>
          <Toolbar>
            <IconButton size="small" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <img src={navicon} alt="omega" style={{ height: 30 }} />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 2, color: 'black' }}>
              TERIC DATA LABS
            </Typography>
            {isMobile ? (
              <>
                <IconButton
                  size="small"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
                {renderMenu}
              </>
            ) : (
              <>
                <Link to="/">
                  <Button color="inherit">Home</Button>
                </Link>
                <Link to="/aboutus">
                  <Button color="inherit">AboutUs</Button>
                </Link>
               <Link to="/products">
                <Button color="inherit">Services</Button>
               </Link>
               <Link to="/blogs">
                <Button color="inherit">Blog</Button>
               </Link>
               <IconButton
                  size="small"
                  edge="end" 
                >
                <img src={ug} style={{width:30, height:30}} alt='ugflag'/>
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default Navbar;
