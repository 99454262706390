import React from "react";
import { BrowserRouter as  Router,Routes, Route } from 'react-router-dom';
import Home from "./screens/Home";
import AboutScreen from "./screens/AboutScreen";
import ProjectScreen from "./screens/ProjectScreen";
import BlogScreen from "./screens/BlogScreen";
function App() {
  return (
    <>
      <Router>
      <Routes>
        <Route  path="/" element={<Home/> }/>
        <Route path="/aboutus" element={<AboutScreen/>} />
        <Route path="/products" element={<ProjectScreen/>} />
        <Route path="/blogs" element={<BlogScreen/>} />
      </Routes>
    </Router>
      
    </>
  );
}

export default App;
