import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flex: '1', // Take 60% of the width
  [theme.breakpoints.down('sm')]: {
    order: 1,
  },
}));

const Detail = ({description, description2, description3 }) => {
  return (
    <StyledCard>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2}}>
        <StyledCardContent>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
          </Box>
          <Box sx={{ my: 4 }}>
            <Typography variant="body1" color="textSecondary">
              {description2}
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" color="textSecondary">
              {description3}
            </Typography>
          </Box>
        </StyledCardContent>
      </Box>
    </StyledCard>
  );
};

export default Detail;
