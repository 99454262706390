import nlp from '../assests/speech.jpg'
import ware from '../assests/ware.jpg'
import dsa from '../assests/datascience.jpg'
import ptn from '../assests/patterns.jpg'
import viz from '../assests/dash.jpg'
import kpi from '../assests/kpi.jpg'
const ServiceData=[
    {
        title:"Natural Language Processing (NLP)",
        img:nlp,
        description:"Developing NLP solutions for text mining, sentiment analysis, chatbots, document classification, and language translation to extract insights and automate language-related tasks"
    },
    {
        title:"Data Engineering and Architecture:",
        img:ware,
        description:"Designing and implementing data pipelines, data warehouses, and scalable infrastructure to ensure efficient data collection, storage, processing, and integration"
    },
    {
        title:"Machine Learning Solutions",
        img:dsa,
        description:"Building custom machine learning models and algorithms to automate processes, detect anomalies, classify data, optimize operations, and deliver personalized recommendations"
    },
    {
        title:"Predictive Analytics",
        img:ptn,
        description:"Developing predictive models to forecast trends, identify patterns, and make data-driven predictions for future outcomes, enabling businesses to make informed decisions"
    },
    {
        title:"Data Visualization and Reporting",
        img:viz,
        description:"Creating interactive dashboards, reports, and visual representations of data to facilitate easy understanding, exploration, and decision-making for clients."
    },
    {
        title:"Data Strategy and Consulting",
        img:kpi,
        description:"Assisting clients in developing data-driven strategies, defining key performance indicators (KPIs), and providing expert guidance on leveraging data analytics and machine learning technologies"
    },

]
export default ServiceData