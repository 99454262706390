import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flex: '1 0 40%', // Take 60% of the width
  [theme.breakpoints.down('sm')]: {
    order: 1,
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  flex:'1 0 60%',// Take 40% of the width
  [theme.breakpoints.down('sm')]: {
    order: 1,
    marginBottom: theme.spacing(2),
  },
}));

const ProCard = ({ title, description, description2, description3, imageUrl }) => {
  return (
    <StyledCard>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2}}>
        <StyledCardContent>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
          </Box>
          <Box sx={{ my: 4 }}>
            <Typography variant="body1" color="textSecondary">
              {description2}
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" color="textSecondary">
              {description3}
            </Typography>
          </Box>
        </StyledCardContent>
      </Box>
      <StyledCardMedia component="img" src={imageUrl} title={title} />
    </StyledCard>
  );
};

export default ProCard;
