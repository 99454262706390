import React  from "react";
import Navbar from "../components/Navbar";
import Welcome from "../components/Welcome";
import Footer from "../components/Footer";

const Home =()=>{
    return(
        <>
        <Navbar/>
        <Welcome/>
        <Footer/>
        </>
    );
}
export default Home;
