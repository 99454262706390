import React  from "react";
import { Box, Typography, Grid } from '@mui/material';
import imghome from '../assests/about.jpg';
import wrk from '../assests/hands.jpg';
import '../App.css';
import Detail from "./Detail";
import EmailFormCard from "./EmailFormCard";


const About = () => {

  return (
    <Box>
      <Box id="main" sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${imghome})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height:'60vh'
      }}>
        <Grid container sx={{justifyContent:"center", pt:5, my:30}}>
          <Grid item xs={12} sm={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} justifyItems={'center'} flexGrow={1}>
            <Box sx={{ p: { xs:8, sm: 8 }  , justifyContent:'center', justifyItems:'center'}}>
              <Typography variant="h2">ABOUT US</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{display:'flex', justifyContent:'center', flexGrow:1, padding:8,flexDirection:'column'}}>
        
        <Box>
          <Detail 
          description={"We are a data analytics company that unlocks the power of your data to drive business success. Our expert team of data scientists and analysts dives deep into complex datasets, uncovering valuable insights that fuel informed decision-making. By leveraging advanced algorithms and cutting-edge techniques, we transform raw information into actionable intelligence. Stay ahead of the competition with our data analytics solutions, optimizing operations, predicting market trends, and understanding customer preferences."} 
          description2={"Join forces with us and embrace the data-driven revolution. We actively seek partnerships with forward-thinking businesses who understand the value of analytics. Our dedicated business development team collaborates closely with clients, tailoring our solutions to meet their specific needs. With transparent pricing, open communication, and a focus on measurable results, we build long-term relationships based on trust and mutual success."}
          description3={"Located at the heart of innovation in Makerere University, Kampala, Uganda, we thrive in an environment that fosters creativity and collaboration. Makerere University, renowned for its academic excellence, provides us with access to top talent and cutting-edge research. Situated in a bustling business hub, we connect with local and international clients, serving diverse industries such as finance, healthcare, and agriculture."}
          />
        </Box>
        <Box>
          <Grid container sx={{justifyContent:"center", pt:5}}>
            <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} flexGrow={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={wrk} alt="optical character recognition" style={{maxWidth: '100%',objectFit: 'cover' , animation: 'slide-in 1s ease-out',}} id="sideimage" />
            </Box>
            </Grid>
            <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} flexGrow={1} >
              <EmailFormCard/>
            </Grid >
          </Grid>
        </Box>
      </Box>
     
    </Box>
  );
}

export default About;
