import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Products from "../components/Products";

const ProjectScreen =()=>{
    return (
        <>
        <Navbar/>
        <Products/>
        <Footer/>
        </>
    );
}
export default ProjectScreen;