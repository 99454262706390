import { Card,CardActionArea, CardContent,Typography, CardMedia} from "@mui/material";
import {CastForEducationRounded} from '@mui/icons-material';

const ServiceCard =(data)=>{
    const {title, img,description} =data;

   

    return(
        <Card sx={{maxWidth: 400, margin:"1rem"}}>
            <CardMedia
                sx={{ height: 200 }}
                component="img"
                src={img}
                title="green iguana"
            />
            <CardActionArea>
                <CardContent>
                    <CastForEducationRounded sx={{color:'blue'}}/>
                    <Typography variant="h5">{title}</Typography>
                    <Typography variant="body2" id="bodi" sx={{ overflow: 'hidden' }}>{description}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>

    );

}
export default ServiceCard