import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import About from "../components/About";
const AboutScreen=()=>{
    return(
        <>
        <Navbar/>
        <About/>
        <Footer/>
        </>
        
    )
}

export default AboutScreen;